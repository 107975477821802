@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Sans+Mono:ital,wght@0,400..700;1,400..700&display=swap');

body {
  font-family: "Ubuntu Sans Mono", monospace, Garamond, Arial, "Helvetica Neue", sans-serif;
  height: 100%;
  height: 100vh;
  box-shadow: 2px 3px 20px #000000, 0 0 60px #8a4d0f inset;
  background: #fffef0;
  display: flex;
}

.container {
  position: absolute;
  align-items: center;
  display: inline;  
  flex-direction: column;
  left: 50%;
  top: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
  justify-content: center;
  color: #000;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}

.subtext-container {
  display: flex;
  flex-direction: row;
}

/* .first-subtext{
  display: inline-block;
} */

.second-subtext{
  display: inline-block;

}

.header {
  display: inline-block;
  font-size: 2em;
  margin-bottom: 5px;
}

.sub-text {
  color: rgb(0, 0, 0);
  font-size: 1.5em;
  line-height: 1.4em;
}

.links {
  display: flex;
  height: auto;
  color: #000;
  margin-top: 20px;
  max-height: 100px;
  overflow: hidden;
  transition: max-height 1s ease-out;
}

#links {
  position: fixed;
  top: 10px;
  right: 30px;
  width: auto;
  height: auto;
}

.links.collapsed {
  max-height: 0;
}

.links .link {
  background-color: transparent;
  color: black;
  border-radius: 90%;
  cursor: pointer;
  margin: 10px 10px;
  padding: 11px;
}

.links .link:hover {
  background-color: transparent;
}

.links .link p {
  font-size: 0.8em;
  margin-bottom: 0;
}

.backlink{
  position: absolute;
  padding-left: 5%;
  padding-top: 5%;
  z-index: 1000;
}

.fill-black {
  fill: #000;
}

.blog-container{
  position: fixed;
  padding-left: 24%;
  padding-top: 10%;
}

.blog-title h2{
  font-size: 1em;
  color: #302f2f;
}

.article-container{
  margin: 10% auto;
  display: absolute;
  overflow-y:auto;
  scrollbar-color: #e0dfcf transparent;
}

article {
  display: grid;
  grid-template-columns: 1fr 1fr 10px 740px 10px 1fr 1fr;
}

article > * {
  grid-column: 4;
}

article > figure {
  grid-column: 2 / -2;
  margin: 20px 0;
}

article > blockquote {
  grid-column: 3 / 5;
  padding-left: 10px;
  color: #666;
  border-left: 3px solid black;
}

.aside {
  grid-column: 5;
}

ul {
  display: list-item;
  list-style-position: inside;
  padding: 0;
  padding-top: 5%;
  margin: 0;
}

a {
  text-decoration: none;
}

a:link, a:visited {
  color: #361e06;
}

a:hover {
  text-decoration: underline;
  text-decoration-color: #361e06;
}


